import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import {
  Card,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash/debounce";
const paginationStyles = {
  overflowX: "auto",
  overflowY: "auto",
  WebkitOverflowScrolling: "touch",
  scrollbarWidth: "thin",
  scrollbarColor: "#888 #e0e0e0",
  height: "calc(100vh - 130px)",
  "@media (max-width: 1200px)": {
    overflowX: "auto",
  },
};

const Overview = () => {
  const [dates, setDates] = useState([]);
  const [IsOverView, setOverView] = useState([]);
  // console.log('IsOverView', IsOverView);
  const [duration, setDuration] = useState(3);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectOpen, setSelectOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dropDownProperty, setDropDownProperty] = useState("");
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const inputRef = useRef(null);
  const durationRef = useRef(null);
  const previousDurationRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [minePrpData, setminePrpData] = useState([]);
  const [tableData, setTableData] = useState(minePrpData);
  console.log("tableData", tableData);
  const [filterBy, setFilterBy] = useState({
    date: new Date().toISOString().split("T")[0] || "",
    duration: 3 || "",
  });

  const generateDates = (finalDate) => {
    const { date, duration } = finalDate;

    // Parse the selected date once
    const startDate = new Date(date);
    if (isNaN(startDate.getTime())) {
      console.error("Invalid start date");
      return [];
    }

    let newDuration = duration || previousDurationRef.current;

    // Generate the list of formatted dates
    const generatedDates = [];
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + newDuration);

    for (
      let currentDate = new Date(startDate + 1);
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const formattedDate = currentDate.toLocaleDateString("en-GB").replace(/-/g, "/");
      generatedDates.push(formattedDate);
    }

    setDates(generatedDates); // Store the array of formatted strings in state
  };

  const OverviewData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/overviewsApi`);
      if (response.status === 200) {
        setOverView(response.data.data);
        setFilteredProperties(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchValue(value);

    if (!value) {
      setFilteredProperties(IsOverView || []);
      return;
    }

    const filteredData =
      IsOverView.length > 0 &&
      IsOverView.filter((property) =>
        (property.property_name || "").toLowerCase().includes(value.toLowerCase())
      );

    setFilteredProperties(filteredData || []);
  };

  const handleDateChange = (event) => {
    inputRef.current.blur();
    const newDate = event.target.value;
    setFilterBy({
      ...filterBy,
      date: newDate,
    });
  };

  useEffect(() => {
    let filteredData = IsOverView;

    // Apply dropdown filter if a property is selected
    if (dropDownProperty) {
      filteredData = filteredData.filter((property) =>
        property.property_name.toLowerCase().includes(dropDownProperty.toLowerCase())
      );
    }

    setFilteredProperties(filteredData); // Update the filtered properties
  }, [dropDownProperty]);

  const handleDurationChange = (event) => {
    if (durationRef.current) {
      durationRef.current.blur();
    }
    const value = event.target.value;
    setFilterBy({
      ...filterBy,
      duration: value,
    });
    setDuration(value);
    setSelectedMonth(value);
  };

  const handleSelectOpen = useCallback(() => {
    setSelectOpen(true);
  }, []);

  const handleSelectClose = useCallback(() => {
    setSelectOpen(false);
  }, []);

  useEffect(() => {
    setSearchValue("");
  }, []);

  useEffect(() => {
    const options = IsOverView.map((property) => property.property_name); // Extract property names
    console.log(options, "optionsoptions");
    setDropDownOptions(options); // Set dropdown options
  }, [IsOverView]);

  const filterPrpData = (selectedDate) => {
    const selectedDateObj = new Date(selectedDate);
    const rows = [];

    filteredProperties?.forEach((property) => {
      const occupancyMap = {};

      // Process each detail only once
      property?.details?.forEach((detail) => {
        const arrivedDate = new Date(detail.arrived_date);
        const departureDate = new Date(detail.departure_date);

        // Skip processing if property is outside the range
        if (departureDate < selectedDateObj) return;

        let currentDate = new Date(Math.max(arrivedDate, selectedDateObj));
        while (currentDate < departureDate) {
          const index = Math.round((currentDate - selectedDateObj) / (1000 * 60 * 60 * 24)); // Days offset
          if (index >= 0) {
            occupancyMap[`date_${index}`] = "Occupied";
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        // Mark departure date as "Occupied"
        const departureIndex = Math.round(
          (departureDate - selectedDateObj) / (1000 * 60 * 60 * 24)
        );
        if (departureIndex >= 0) {
          occupancyMap[`date_${departureIndex}`] = "Occupied";
        }
      });

      // Fill remaining dates as "Available" only where not already "Occupied"
      for (let index = 0; index < dates.length; index++) {
        occupancyMap[`date_${index}`] = occupancyMap[`date_${index}`] || "Available";
      }

      rows.push({
        name: property.property_name,
        id: property.property_id,
        dragid: property.dragdropid,
        ...occupancyMap,
      });
    });

    setminePrpData(rows);
  };

  useEffect(() => {
    minePrpData && setTableData(minePrpData);
  }, [minePrpData]);

  // const handleDragStart = (e, draggedIndex) => {
  //   console.log("gragstart" , draggedIndex  , "ee" , e)
  //   e.dataTransfer.setData("draggedIndex", draggedIndex);
  // };

  // const handleDragOver = (e) => {
  //   console.log("handleDragOver")
  //   e.preventDefault(); // Prevent default to allow dropping
  // };

  // const handleDrop = async (e, targetIndex) => {
  //   console.log("handleDrophandleDrophandleDrop" , targetIndex , "e" , e)
  //   const draggedIndex = parseInt(e.dataTransfer.getData("draggedIndex"), 10);
  //   console.log('draggedIndex', draggedIndex, 'targetIndex', targetIndex)
  //   if (draggedIndex !== targetIndex) {
  //     console.log('hiiii')
  //     const updatedData = [...tableData];
  //     console.log('updatedData', updatedData)
  //     const [draggedRow] = updatedData.splice(draggedIndex, 1); // Remove dragged row
  //     updatedData.splice(targetIndex, 0, draggedRow); // Insert dragged row at target position

  //     setTableData(updatedData);

  //   }
  // }

  // useEffect(async()=>{
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_PATH}/updateOverview/dragdropid`
  //       , {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(tableData), // Send the reordered data to the server
  //     });

  //     const result = await response.json();
  //     if (response.ok) {
  //       console.log('Row order updated successfully:', result);
  //     } else {
  //       console.error('Error updating row order:', result);
  //     }
  //   } catch (error) {
  //     console.error('Error sending request:', error);
  //   }
  // },[tableData])

  useEffect(() => {
    filterPrpData(filterBy.date);
  }, [filterBy.date, filteredProperties]);

  useEffect(() => {
    generateDates(filterBy);
  }, [filterBy]);
  const renderCellContent = (status) => {
    const isOccupied = status === "Occupied";
    return (
      <p
        style={{
          fontSize: "14px",
          color: isOccupied ? "red" : "green",
          backgroundColor: isOccupied ? "red" : "green",
          padding: "6px 4px",
          display: "block",
          margin: "0px",
        }}
      >
        {status}
      </p>
    );
  };

  useEffect(() => {
    OverviewData();
  }, []);

  return (
    <DashboardLayout>
      <Sidenav color="primary" brandName="RentRoll" routes={routes} />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.8}
                px={2}
                variant="gradient"
                sx={{ backgroundColor: "#1e90ff" }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" pt={2.7} color="white" style={{ float: "left" }}>
                  Dashboard
                </MDTypography>

                <MDBox mt={4} mb={1} style={{ float: "right", display: "flex", gap: "10px" }}>
                  {/* <TextField
                    placeholder="Search Properties"
                    size="small"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "#ffffff",
                        color: "#000000",
                        border: "none",
                        padding: "5px 10px",
                      },
                      "& .MuiInputLabel-root": {
                        display: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px",
                      },
                    }}
                    inputProps={{ "aria-label": "Search" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="search">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleSearch}
                  /> */}
                  <MDBox mb={2} mr={2} width={200}>
                    <Autocomplete
                      options={dropDownOptions} // Use dropdown options
                      getOptionLabel={(option) => option} // Options are strings (property names)
                      onChange={(event, value) => setDropDownProperty(value || "")} // Update selected property
                      value={dropDownProperty}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          
                          placeholder="Search"
                          sx={{
                            background: "white",
                            color: "black",
                            border: "none",
                            margin: "0px",
                            ".MuiOutlinedInput-root": {
                              padding: "5px", // Set padding to 2px
                            },
                            ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none", // Remove the border on focus
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none", // Remove the border for normal state
                            },
                          }}
                        />
                      )}
                    />
                  </MDBox>

                  <FormControl variant="outlined" style={{ minWidth: 120 }}>
                    <InputLabel id="start-date" style={{ color: "#ffff", marginTop: "-40px" }}>
                      Select Date
                    </InputLabel>
                    <TextField
                      id="start-date"
                      type="date"
                      variant="outlined"
                      onChange={handleDateChange}
                      value={filterBy.date}
                      onBlur={() => setSelectOpen(false)}
                      onClose={() => setSelectOpen(false)}
                      inputRef={inputRef}
                      sx={{
                        "& .MuiInputBase-root": {
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          border: "none",
                          padding: "4px 10px",
                        },
                        "& .MuiInputLabel-root": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          display: "none",
                        },
                        "& .MuiInputBase-input": {
                          padding: "8px",
                        },
                      }}
                      inputProps={{ "aria-label": "start" }}
                    />
                  </FormControl>
                  <FormControl variant="outlined" style={{ minWidth: 120 }}>
                    <InputLabel
                      id="duration-select-label"
                      style={{ color: "#ffff", marginTop: "-21px", fontSize: "20px" }}
                    >
                      Duration
                    </InputLabel>
                    <Select
                      labelId="duration-select-label"
                      id="demo-simple-select-helper"
                      value={filterBy.duration}
                      durationRef={durationRef}
                      onChange={handleDurationChange}
                      open={selectOpen}
                      onOpen={handleSelectOpen}
                      onBlur={handleSelectClose}
                      onClose={handleSelectClose}
                      label="Duration (Months)"
                      sx={{ background: "#ffff", padding: "12px 5px" }}
                      inputProps={{ "aria-label": "demo-simple-select-helper" }}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                        <MenuItem key={month} value={month}>
                          {`${month} Month${month > 1 ? "s" : ""}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
              </MDBox>

              <MDBox pt={3}>
                {loading ? (
                  <>
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      align="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                    </MDTypography>
                  </>
                ) : (
                  <div style={paginationStyles}>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        backgroundColor: "#fff",
                      }}
                    >
                      <thead
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "#f5f5f5",
                          zIndex: 1,
                        }}
                      >
                        <tr style={{ fontSize: "14px" }}>
                          <th
                            style={{
                              padding: "0px 8px",
                              borderBottom: "2px solid #ddd",
                              textAlign: "left",
                              position: "sticky",
                              left: 0,
                              backgroundColor: "#fff",
                              zIndex: 1,
                            }}
                          >
                            Name
                          </th>
                          {dates.map((date, index) => (
                            <th
                              key={index}
                              style={{
                                padding: "0px 0px",
                                borderBottom: "1px solid #808080",
                                borderRight: "1px solid #808080",
                                backgroundColor: "#fff",
                                textAlign: "center",
                              }}
                            >
                              {date}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "14px" }}>
                        {minePrpData.length === 0 ? (
                          <tr>
                            <td
                              colSpan={dates.length + 1}
                              style={{
                                textAlign: "left",
                                padding: "10px",
                                backgroundColor: "#f5f5f5",
                              }}
                            >
                              <td
                                variant="caption"
                                style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}
                              >
                                No data found
                              </td>
                            </td>
                          </tr>
                        ) : (
                          tableData.map((row, index) => (
                            <tr
                            // key={row.dragid}
                            // draggable ={true}
                            // onDragStart={(e) => handleDragStart(e, index)}

                            // onDragOver={handleDragOver}
                            // onDrop={(e) => handleDrop(e, index)}
                            >
                              <td
                                style={{
                                  padding: "0px 6px",
                                  borderBottom: "1px solid #808080",
                                  borderRight: "1px solid #808080",
                                  minWidth: "190px",
                                  position: "sticky",
                                  left: 0,
                                  backgroundColor: "#fff",
                                  zIndex: 1,
                                }}
                              >
                                {row.name}
                              </td>
                              {dates.map((date, index) => (
                                <td
                                  key={index}
                                  style={{
                                    padding: "0px 0px",
                                    borderBottom: "1px solid #808080",
                                    borderRight: "1px solid #808080",
                                    textAlign: "center",
                                  }}
                                >
                                  {renderCellContent(row[`date_${index}`] || "Available")}
                                </td>
                              ))}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Overview;
