import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Card, Grid, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { ToastContainer, toast } from "react-toastify";
import MDButton from "components/MDButton";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const changepassword = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState();
  const passwordFormik = useFormik({
    initialValues: {
      oldpassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      if (values.newPassword !== values.confirmPassword) {
        toast.error("Passwords do not match!");
        return;
      }

      try {
        const response = await axios.put(`${process.env.REACT_APP_API_PATH}/changepassword`, {
          oldpassword: values.oldpassword,
          newPassword: values.newPassword,
          id:userId,
        });

        if (response.status === 200) {
          localStorage.removeItem("token");
          toast.success("Password changed successfully!");
          setTimeout(() => {
            navigate("/");
          }, 2000); 
        } else {
          toast.error("Error changing password: " + response.data.message);
        }
      } catch (error) {
        console.error("Error changing password:", error);
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred while changing password.");
        }
      }
    },
    validate: (values) => {
      const errors = {};
      if (!values.oldpassword) errors.oldpassword = "Old Password is required";
      if (!values.newPassword) {
        errors.newPassword = "New Password is required";
      } else if (
        !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/i.test(
          values.newPassword
        )
      ) {
        errors.newPassword =
          "Must contain 8 characters, one uppercase, one lowercase, one number, and one special case character.";
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm Password is required";
      } else if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = "Passwords must match";
      }

      return errors;
    },
  });
  const userProfile = () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(`${process.env.REACT_APP_API_PATH}/getregisterUser`, { token })
        .then((res) => {
          if (res.data.status === 200) {
            const userData = res.data.data;
            if (userData.length > 0) {
              setUserId(userData[0].id);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    userProfile();
  }, []);
  // const handleBack = () => {
  //   navigate(-1);
  // };
  return (
    <DashboardLayout>
    <Sidenav color="primary" brandName="RentRoll" routes={routes} />
    <Box  sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh", 
        }}>
      <form onSubmit={passwordFormik.handleSubmit}>
        <Card
          sx={{
            width: {
              xs: "90%",
              sm: "80%",
              md: "400px",
              lg: "50%",
              xl: "500px",
            },
          }}
        >
          <MDBox
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
            sx={{ backgroundColor: "#1e90ff" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Change Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3} position="relative" textAlign="center">
            {["Old Password", "New Password", "Confirm Password"].map((label, index) => {
              const name =
                index === 0 ? "oldpassword" : index === 1 ? "newPassword" : "confirmPassword";
              return (
                <MDBox mb={2} key={label}>
                <TextField
                  type={name === "oldpassword" ? "password" : "text"}
                  label={label}
                  fullWidth
                  name={name}
                  value={passwordFormik.values[name]}
                  onChange={passwordFormik.handleChange}
                  onBlur={passwordFormik.handleBlur}
                  error={passwordFormik.touched[name] && Boolean(passwordFormik.errors[name])}
                  helperText={passwordFormik.touched[name] && passwordFormik.errors[name]}
                  InputProps={{
                    endAdornment: passwordFormik.touched[name] && passwordFormik.errors[name] ? (
                      <InputAdornment position="end">
                        <ErrorOutlineIcon color="error" />
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </MDBox>
              
              );
            })}
            <MDBox mt={4} mb={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <MDButton variant="gradient" color="info" fullWidth type="submit">
                    Change Password
                  </MDButton>
                </Grid>
                {/* <Grid item xs={6} md={6}>
                  <MDButton variant="gradient" color="info" fullWidth onClick={handleBack}>
                    <ArrowBackIcon style={{ marginRight: "8px" }} /> Back
                  </MDButton>
                </Grid> */}
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </form>
      <ToastContainer limit={1} className="custom-toast" />
    </Box>
     </DashboardLayout>
  );
};

export default changepassword;
