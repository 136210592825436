import { useEffect, useState } from "react";
// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import doLogout from "../../components/helper.js";
import Swal from "sweetalert2";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const location = useLocation();
  const collapseName = location?.pathname.replace("/", "") || "";
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(!isSmallScreen);
  const [openProperties, setOpenProperties] = useState(false); 
  const miniSidenav = false;
  const transparentSidenav = false;
  const whiteSidenav = false;
  const darkMode = false;
  const sidenavColor = "info";

  const navigate = useNavigate();

  const handleButtonClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d6efd",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await doLogout();
        if (response.data.status === 200) {
          localStorage.removeItem("token");
          navigate("/");
        }
      }
    });
  };

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  const handleToggleProperties = () => {
    setOpenProperties((prevState) => !prevState);
  };

  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route ,subRoutes}) => {
    let returnValue;

    if (type === "collapse") {
      if (key === "properties") {
        returnValue = (
          <div key={key}>
            <div
              onClick={handleToggleProperties}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
               
              }}
            >
              <SidenavCollapse name={name} icon={icon} active={key === collapseName} style={{ background: openProperties ? 'transparent' : 'transparent',}}/>
              <div style={{ marginRight: '50px' }}>
                {openProperties ? <ExpandLessIcon color="white" /> : <KeyboardArrowDownIcon color="white" />}
              </div>
            </div>
            {openProperties && subRoutes && (
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{
                  borderLeft: '2px solid #ffffff',
                  height: `${subRoutes.length * 50}px`,
                  marginRight: '0px',
                  marginLeft: '40px',
                  position: 'relative',
                }} />
                
                <div>
                  {subRoutes.map((subRoute) => (
                    <div key={subRoute.key} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                      <NavLink    key={subRoute.key} to={subRoute.route} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', width: '100%' }}>
                        <SidenavCollapse
                          name={subRoute.name}
                          icon={subRoute.icon}
                          active={subRoute.key === collapseName}
                          noCollapse={noCollapse}
                          sx={{
                            background: subRoute.key === collapseName ? 'linear-gradient(195deg, #49a3f1, #1A73E8)' : 'transparent',
                            borderRadius: '4px',
                            padding: '8px 12px',
                            '&:hover': {
                              background: 'rgba(255, 255, 255, 0.1)',
                            },
                          }}
                        />
                      </NavLink>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse
              sx={{
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                },
              }}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      }
      

    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  const handleToggle = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <>
      {isSmallScreen && <MenuIcon onClick={handleToggle} />}
      <SidenavRoot
        {...rest}
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={open}
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <MDBox pt={3} pb={1} px={2} textAlign="center">
          {isSmallScreen && (
            <MDBox
              display={{ xs: "block", xl: "none" }}
              position="absolute"
              top={0}
              right={0}
              p={1.625}
              sx={{ cursor: "pointer" }}
            >
              <IconButton onClick={handleToggle} style={{color:"white", marginTop:"-9px"}}>
                {open ? (
                  <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                ) : (
                  <Icon sx={{ fontWeight: "bold" }}>menu</Icon>
                )}
              </IconButton>
            </MDBox>
          )}
          <MDBox component={NavLink} to="/" display="flex" alignItems="center">
            {/* <MDBox component="img" src={Logo} alt="Brand" width="12rem" /> */}
            <MDTypography variant="h3"   color="white">
            Furnished Tokyo
            </MDTypography>
            <MDBox
              width={!brandName && "100%"}
              sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            />
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <List> {renderRoutes}</List>
        <MDBox p={2} mt="auto">
          <MDButton
            sx={{ backgroundColor: "#0d6efd" }}
            color={sidenavColor}
            fullWidth
            onClick={handleButtonClick}
          >
            Logout
          </MDButton>  
        </MDBox>
      </SidenavRoot>
    </>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
