import * as React from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Button, Card, Grid, TextField, FormControl, Typography } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useState } from "react";
const style = {
  position: "absolute",
  top: { xs: 300, sm: 300, md: 300, xl: 350 },
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
};

export default function ModelData({ isVisible, addProperty, close, selectedProperty }) {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isPdfFile, setIsPdfFile] = useState("");
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      const name = url.split("/")[3];
      setIsPdfFile(name);
      setFileUrl(url || "");
      setFile(selectedFile);
    }
  };
  const openFileInNewTab = () => {
    const fileToOpen = fileUrl || fileName;
    if (fileToOpen) {
      window.open(fileToOpen, "_blank");
    } else {
      toast.error("No file available to open!");
    }
  };

  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("property_name", values.property_name);
      formData.append("city", values.city || "");
      formData.append("zip", values.zip || "");
      formData.append("ward", values.ward || "");
      formData.append("location", values.location || "");
      formData.append("street", values.street || "");
      if (file) {
        formData.append("pdf_file", file || fileName);
      } else if (selectedProperty && selectedProperty.pdf_file) {
        formData.append("pdf_file", selectedProperty.pdf_file);
      }
      let response;

      if (selectedProperty) {
        response = await axios.put(
          `${process.env.REACT_APP_API_PATH}/updateProperties/${selectedProperty.property_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(`${process.env.REACT_APP_API_PATH}/addProperties`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (response && response.status === 200) {
        toast.success(
          selectedProperty ? "Property data updated successfully!" : "Property added successfully!"
        );
        addProperty(values);
        setFile("");           
        setFileName("");  
        clearFormAndFileData();
      }
    } catch (error) {
      console.error("Failed to add/update Property data. Please try again.", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      property_name: "",
      city: "",
      zip: "",
      ward: "",
      location: "",
      street: "",
    },
    onSubmit: async (values) => {
      onSubmit(values);
    },
    validate: (values) => {
      const errors = {};
      if (!values.property_name) errors.property_name = "Property name is required";

      return errors;
    },
  });
  const clearFormAndFileData = () => {
    setFileUrl("");
    setFileName("");
    setIsPdfFile("");
    formik.resetForm();
    close();
  };

  useEffect(() => {
    if (selectedProperty) {
      formik.setValues({
        property_name: selectedProperty.property_name || "",
        city: selectedProperty.city || "",
        zip: selectedProperty.zip || "",
        ward: selectedProperty.ward || "",
        location: selectedProperty.location || "",
        street: selectedProperty.street || "",
        pdf_file: selectedProperty.pdf_file || "",
      });
      setFile(selectedProperty.pdf_file || "");
      setFileName(selectedProperty.pdf_file || "");
    } else {
      formik.resetForm();
      setFileName("");
      setFileUrl("");
    }
  }, [selectedProperty]);

 
  const isFormFilled = Object.values(formik.values).some((value) => value.trim() !== "");
  const splitFile = fileName.split("/uploads/").pop();
  return (
    <>
      <Modal
        open={isVisible}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Card
            sx={{
              ...style,
              width: {
                xs: "90%",
                sm: "80%",
                md: "500px",
                lg: "60%",
                xl: "600px",
              },
            }}
          >
            <MDBox
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              sx={{ backgroundColor: "#1e90ff" }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                {selectedProperty ? "Edit Property" : "Add Property"}
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Grid container spacing={2}>
                {[
                  { label: "Property name", name: "property_name" },
                  { label: "City", name: "city" },
                  { label: "Zip Code", name: "zip" },
                  { label: "Ward", name: "ward" },
                  { label: "Street", name: "street" },
                ].map(({ label, name }) => (
                  <Grid item xs={6} key={name}>
                    <MDBox mb={2}>
                      <MDInput
                        label={label}
                        fullWidth
                        name={name}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div style={{ color: "red", fontSize: "17px" }}>
                        {formik.touched[name] && formik.errors[name] && (
                          <div>{formik.errors[name]}</div>
                        )}
                      </div>
                    </MDBox>
                  </Grid>
                ))}
                <Grid item xs={6}>
                  <MDBox display="flex" alignItems="center" mb={2}>
                    <FormControl fullWidth>
                    <Typography variant="body2" style={{ color: "#666b6f", fontSize:"14px" }} mt={-2.5}>
                       Property Access Guide
                      </Typography>
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<AttachFileIcon style={{ color: "#7b809a" }} />}
                        sx={{
                          marginRight: "1rem",
                          "&:hover": {
                            borderColor: "#0d6efd",
                          },
                        }}
                      >
                        <span style={{ color: "#7b809a" }}>Upload PDF</span>
                        <input
                          type="file"
                          accept="application/pdf,.pdf, .jpg, .jpeg, .png, .doc, .docx, .txt"
                          hidden
                          onChange={handleFileChange}
                        />
                      </Button>
                      
                      <MDTypography
                        variant="caption"
                        onClick={openFileInNewTab}
                        sx={{ marginTop: 1, cursor: "pointer", fontSize: "14px", color: "#007bff" }}
                      >
                        {fileUrl ? (
                          <Typography variant="caption">{fileUrl}</Typography>
                        ) : splitFile ? (
                          <Typography variant="caption">{splitFile}</Typography>
                        ) : (
                          null
                        )}
                      </MDTypography>
                    </FormControl>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mt={1} mb={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                    <MDButton
                  sx={{ backgroundColor: "#0d6efd" }}
                  color="info"
                  fullWidth
                  type="submit"
                  disabled={!isFormFilled}
                >
                  Submit
                </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <MDButton
                  sx={{ backgroundColor: "#0d6efd" }}
                  color="info"
                  fullWidth onClick={clearFormAndFileData}>
                  Close
                </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
            </MDBox>
          </Card>
        </form>
      </Modal>
      {/* <ToastContainer limit={0} /> */}

    </>
  );
}
