import React from "react";
import "react-toastify/dist/ReactToastify.css";
import ApartmentIcon from "@mui/icons-material/Apartment";

function Nav() {
  return (
    <>
      <header id="header" style={{ position: "relative", zIndex: 1000 }}>
        <nav
          className="navbar navbar-expand-lg navbar-light"
          style={{ backgroundColor: "#ffffff", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
        >
          <div
            className="navbar-collapse d-flex align-items-center"
            style={{ paddingLeft: "20px" }}
          >
            <h3 className="logo-text">
              <ApartmentIcon
                className="fa fa-home"
                style={{ fontSize: "1.5rem", marginRight: "10px" }}
              />
              Furnished Tokyo
            </h3>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Nav;
