import { Typography } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddHomeIcon from "@mui/icons-material/AddHome";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import MoneyIcon from "@mui/icons-material/Money";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
const routes = [
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Dashboard</Typography>,
    key: "dashboard",
    icon: <DashboardIcon fontSize="small" />,
    route: "/dashboard",
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Properties</Typography>,
    key: "properties/add-properties",
    route: "/properties/add-properties",
    icon: <ApartmentIcon fontSize="small" />,
    // subRoutes: [
    //   {
    //     name: <Typography sx={{ fontSize: "14px" }}>Add Properties</Typography>,
    //     key: "properties/add-properties",
        
    //     icon: <AddHomeIcon fontSize="small" />,
    //   },
    //   // {
    //   //   name: <Typography sx={{ fontSize: "14px" }}>List Properties</Typography>,
    //   //   key: "properties/list-properties",
    //   //   route: "/properties/list-properties",
    //   //   icon: <ListAltIcon fontSize="small" />,
    //   // },
    // ],
  },
  
  // {
  //   type: "collapse",
  //   name: <Typography sx={{ fontSize: "16px" }}>Reservations</Typography>,
  //   key: "room-reservation",
  //   icon: <BookOnlineIcon fontSize="small" />,
  //   route: "/room-reservation",
  // },
  // {
  //   type: "collapse",
  //   name: <Typography sx={{ fontSize: "16px" }}>Cleaning</Typography>,
  //   key: "cleaning",
  //   icon: <CleaningServicesIcon fontSize="small" />,
  //   route: "/cleaning",
  // },
  // {
  //   type: "collapse",
  //   name: <Typography sx={{ fontSize: "16px" }}>Expense</Typography>,
  //   key: "expense",
  //   icon: <MoneyIcon fontSize="small" />,
  //   route: "/expense",
  // },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Manage Lists</Typography>,
    key: "manage-reservation",
    icon: <PlaylistAddIcon fontSize="small" />,
    route: "/manage-reservation",
  },
  // {
  //   type:"collapse",
  //   name : <Typography sx={{fontSize:"16px"}}>Monthly Report</Typography>,
  //   route:"/monthly",
  //   key:"monthly",
  //   icon:<CalendarMonthIcon fontSize="small"/>
  //   },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Profile</Typography>,
    key: "profile",
    icon: <AccountCircleIcon fontSize="small" />,
    route: "/profile",
  },
  
];

export default routes;
