import * as React from "react";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Button, Card, Grid, Autocomplete, TextField, FormControl, Modal } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
const style = {
  position: "absolute",
  top: { xs: 340, sm: 340, md: 340, xl: 400 },
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  pt: 2,
  pb: 3,
};

const CleaningModel = ({ isVisible, Close, fetchCleaningData, getCleaningDataOnId , roomId }) => {
 
  const [IsSelectProperty, setSelectProperty] = useState(null);
  const [properties, setProperties] = useState([]);
  const PropertiesId = IsSelectProperty || roomId?.property_id;
  const location = useLocation();
  const {property_id} = useParams();
  console.log(property_id , "property_id")
  const propertyIdFromParams = location.pathname !== "/properties/list-properties" && location.pathname !== "/properties/add-properties"; 
  const formik = useFormik({
    initialValues: {
      cleaner_email: "",
      cleaner_name: "",
      todo: "",
      maintenance: "",
      cleaning_date: "",
      property: PropertiesId,
    },
    onSubmit: async (values) => {
      
      try {
        const formData = {
          ...values,
          property_id: PropertiesId,
        };
        let response;
        response = await axios.post(`${process.env.REACT_APP_API_PATH}/cleaningdata/${property_id || PropertiesId}`, formData);

        closeModal();
        if (response.data.status === 200) {
          toast.success("Cleaning data submitted successfully!");
        } else {
          toast.error("Failed to submit cleaning data.");
        }
        getCleaningDataOnId()
        fetchCleaningData(formData);
        
        formik.resetForm();
      } catch (error) {
        console.error("Failed to add/update cleaning data. Please try again.", error);
      }
    },
  });

  const isFormFilled = Object.values(formik.values).some(
    (value) => typeof value === "string" && value.trim() !== ""
  );

  const addProperty = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getproperties`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setProperties(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    }
  };

  useEffect(() => {
    addProperty();
  }, []);

  const closeModal = () => {
    formik.resetForm();
    Close();
  };

  return (
    <>
      <Modal
        open={isVisible}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Card
            sx={{
              ...style,
              width: {
                xs: "90%",
                sm: "80%",
                md: "500px",
                lg: "60%",
                xl: "600px",
              },
            }}
          >
            <MDBox
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              sx={{ backgroundColor: "#1e90ff" }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Add cleaning 
              </MDTypography>
            </MDBox>
            {/* <MDBox pt={4} pb={3} px={3}>
              <Grid container spacing={2}>
                {propertyIdFromParams && (
                  <>
                   
                  </>
                )}
                {[
                  { label: "Cleaning Date", name: "cleaning_date", type: "date" },
                  { label: "Cleaner name", name: "cleaner_name", type: "select" },
                  { label: "Cleaner email", name: "cleaner_email", type: "email" },
                  { label: "Maintenance", name: "maintenance", type: "textarea" },
                  { label: "Todo List", name: "todo", type: "textarea" },
                ].map(({ label, name, options, type = "text" }) => (
                  <Grid item xs={type === "date" && location.pathname != '/cleaning' ?  6 : 6} key={name}>
                    <MDBox mb={2}>
                      {options ? (
                        <Autocomplete
                          disablePortal
                          options={options}
                          value={formik.values[name]}
                          onChange={(event, value) => {
                            formik.setFieldValue(name, value);
                          }}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={label}
                              error={formik.touched[name] && Boolean(formik.errors[name])}
                            />
                          )}
                        />
                      ) : type === "textarea" ? (
                        <TextField
                          label={label}
                          name={name}
                          value={formik.values[name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          multiline
                          rows={4}
                          variant="outlined"
                        />
                      ) : (
                        <MDInput
                          type={type}
                          label={label}
                          fullWidth
                          name={name}
                          value={formik.values[name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            placeholder: type === "date" || type === "time" ? "" : undefined,
                          }}
                        />
                      ) }
                      <div style={{ color: "red", fontSize: "17px" }}>
                        {formik.touched[name] && formik.errors[name] && (
                          <div>{formik.errors[name]}</div>
                        )}
                      </div>
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            
              <MDBox mt={4} mb={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                    <MDButton
                  sx={{ backgroundColor: "#0d6efd" }}
                  color="info"
                  fullWidth
                  type="submit"
                  disabled={!isFormFilled}
                >
                  Submit
                </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <MDButton
                  sx={{ backgroundColor: "#0d6efd" }}
                  color="info"
                  fullWidth  onClick={closeModal}>
                  Close
                </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              <MDTypography color="error" variant="h6"></MDTypography>
            </MDBox> */}
            <MDBox pt={4} pb={3} px={3}>
  <Grid container spacing={2}>
    {propertyIdFromParams && (
      <>
        {/* Your additional content goes here */}
      </>
    )}
    {/* Cleaning Date in one row */}
    <Grid item xs={12} key="cleaning_date">
      <MDBox mb={2}>
        <TextField
          label="Cleaning Date"
          name="cleaning_date"
          type="date"
          value={formik.values.cleaning_date}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{ placeholder: "" }}
          error={formik.touched.cleaning_date && Boolean(formik.errors.cleaning_date)}
        />
        <div style={{ color: "red", fontSize: "17px" }}>
          {formik.touched.cleaning_date && formik.errors.cleaning_date && (
            <div>{formik.errors.cleaning_date}</div>
          )}
        </div>
      </MDBox>
    </Grid>

    {/* Cleaner Name and Cleaner Email in one row */}
    <Grid item xs={6} key="cleaner_name">
      <MDBox mb={2}>
        <TextField
          label="Cleaner Name"
          name="cleaner_name"
          value={formik.values.cleaner_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          error={formik.touched.cleaner_name && Boolean(formik.errors.cleaner_name)}
        />
        <div style={{ color: "red", fontSize: "17px" }}>
          {formik.touched.cleaner_name && formik.errors.cleaner_name && (
            <div>{formik.errors.cleaner_name}</div>
          )}
        </div>
      </MDBox>
    </Grid>

    <Grid item xs={6} key="cleaner_email">
      <MDBox mb={2}>
        <TextField
          label="Cleaner Email"
          name="cleaner_email"
          type="email"
          value={formik.values.cleaner_email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          error={formik.touched.cleaner_email && Boolean(formik.errors.cleaner_email)}
        />
        <div style={{ color: "red", fontSize: "17px" }}>
          {formik.touched.cleaner_email && formik.errors.cleaner_email && (
            <div>{formik.errors.cleaner_email}</div>
          )}
        </div>
      </MDBox>
    </Grid>

    {/* Todo List and Maintenance in one row */}
    <Grid item xs={6} key="todo">
      <MDBox mb={2}>
        <TextField
          label="Todo List"
          name="todo"
          value={formik.values.todo}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
        />
        <div style={{ color: "red", fontSize: "17px" }}>
          {formik.touched.todo && formik.errors.todo && (
            <div>{formik.errors.todo}</div>
          )}
        </div>
      </MDBox>
    </Grid>

    <Grid item xs={6} key="maintenance">
      <MDBox mb={2}>
        <TextField
          label="Maintenance"
          name="maintenance"
          value={formik.values.maintenance}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
        />
        <div style={{ color: "red", fontSize: "17px" }}>
          {formik.touched.maintenance && formik.errors.maintenance && (
            <div>{formik.errors.maintenance}</div>
          )}
        </div>
      </MDBox>
    </Grid>
  </Grid>

  <MDBox mt={4} mb={1}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <MDButton
          sx={{ backgroundColor: "#0d6efd" }}
          color="info"
          fullWidth
          type="submit"
          disabled={!isFormFilled}
        >
          Submit
        </MDButton>
      </Grid>
      <Grid item xs={12} md={6}>
        <MDButton
          sx={{ backgroundColor: "#0d6efd" }}
          color="info"
          fullWidth
          onClick={closeModal}
        >
          Close
        </MDButton>
      </Grid>
    </Grid>
  </MDBox>
  <MDTypography color="error" variant="h6"></MDTypography>
</MDBox>

          </Card>
        </form>
      </Modal>
      <ToastContainer limit={1} />
    </>
  );
};

export default CleaningModel;
