import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { FormControl, InputLabel } from "@mui/material";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField, InputAdornment, Button, Pagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { WidthFull } from "@mui/icons-material";
import { utils, writeFile } from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";

const Index = () => {
  const [Finance, setFinance] = useState([]);
  const [filteredFinance, setFilteredFinance] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const currentMonth = new Date().toISOString().split("T")[0].slice(0, 7);
  const [selectDate, setSelectDate] = useState(currentMonth);
  console.log("selectDate", selectDate);

  const FinanceReport = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/reportgenerator`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setFinance(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const filteredData = Finance.filter((reserve) =>
      (reserve.property_name || "").toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFinance(filteredData);
  }, [searchTerm, Finance]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handledatechange = (event) => {
    setSelectDate(event.target.value);
  };

  useEffect(() => {
    FinanceReport();
  }, []);

  const filteredRooms = filteredFinance.filter((property) => property && property.property_id);
  // const handleExport = () => {
  //   const headings = [["Property Name", "Gross Income", "Occupancy(days)", "Occupancy(rate)",  "Daily rent rate" ,  "Other expence"]];

  //   const data = filteredRooms.map(report => [
  //     report.property_name || '',
  //    isNaN(report.GrossIncomeByMonth[`${selectDate}`]) ? 0 : Number(report.GrossIncomeByMonth[`${selectDate}`]).toFixed(2)    || '0.00',
  //      report.occupiedDaysByMonth[`${selectDate}`]   || '0',
  //    isNaN(report.occupiedPercentageByMonth[`${selectDate}`]) ? 0 : Number(report.occupiedPercentageByMonth[`${selectDate}`]).toFixed(2) || '0.00',
  //    isNaN(report.avgDailyRentByMonth[`${selectDate}`]) ? 0 : Number(report.avgDailyRentByMonth[`${selectDate}`]).toFixed(2)      || '0.00',
  //    isNaN(report.otherExpenseOfMonth[`${selectDate}`]) ? 0 : Number(report.otherExpenseOfMonth[`${selectDate}`]).toFixed(2)
  //   ]);
  //   const worksheetData = [...headings, ...data];

  //   const wb = utils.book_new();
  //   const ws = utils.aoa_to_sheet(worksheetData);
  //   utils.book_append_sheet(wb, ws, "Property Data Report");

  //   writeFile(wb, "PropertyData_Report.xlsx");
  // };
  const handleExport = () => {
    const headings = [
      [
        "Property Name",
        "Gross Income",
        "Occupancy(days)",
        "Occupancy(rate)",
        "Daily rent rate",
        "Other expense",
      ],
    ];

    const data = filteredRooms.map((report) => [
      report.property_name || "",
      isNaN(report.GrossIncomeByMonth[`${selectDate}`])
        ? 0
        : Number(report.GrossIncomeByMonth[`${selectDate}`]).toFixed(2) || "0.00",
      report.occupiedDaysByMonth[`${selectDate}`] || "0",
      isNaN(report.occupiedPercentageByMonth[`${selectDate}`])
        ? 0
        : Number(report.occupiedPercentageByMonth[`${selectDate}`]).toFixed(2) || "0.00",
      isNaN(report.avgDailyRentByMonth[`${selectDate}`])
        ? 0
        : Number(report.avgDailyRentByMonth[`${selectDate}`]).toFixed(2) || "0.00",
      isNaN(report.otherExpenseOfMonth[`${selectDate}`])
        ? 0
        : Number(report.otherExpenseOfMonth[`${selectDate}`]).toFixed(2),
    ]);

    const worksheetData = [...headings, ...data];

    const totalRow = [
      "Total",
      totals.totalGrossIncome,
      totals.totalOccupiedDays,
      totals.totalOccupancyRate,
      totals.totalDailyRate,
      totals.totalOtherExpenses,
    ];

    worksheetData.push(totalRow);

    // Create worksheet and workbook
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(worksheetData);

    // Apply styles for headings and total row
    const headingRowIndex = 0;
    const totalRowIndex = worksheetData.length - 1;

    // Define styles
    const boldStyle = {
      font: { bold: true, size: 12 },
    };

    // Apply bold style to header row
    const range = utils.decode_range(ws["!ref"]);
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = utils.encode_cell({ r: headingRowIndex, c: col });
      if (ws[cellAddress]) {
        ws[cellAddress].s = boldStyle;
      }
    }

    // Apply bold style to the total row
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = utils.encode_cell({ r: totalRowIndex, c: col });
      if (ws[cellAddress]) {
        ws[cellAddress].s = boldStyle;
      }
    }

    // Append sheet to workbook
    utils.book_append_sheet(wb, ws, "Property Data Report");

    // Save the file
    writeFile(wb, "PropertyData_Report.xlsx");
  };
  const calculateTotals = () => {
    let totalGrossIncome = 0;
    let totalOccupiedDays = 0;
    let totalOccupancyRate = 0;
    let totalDailyRate = 0;
    let totalOtherExpenses = 0;

    filteredRooms?.forEach((property) => {
      totalGrossIncome += Number(property.GrossIncomeByMonth[selectDate]) || 0;
      totalOccupiedDays += Number(property.occupiedDaysByMonth[selectDate]) || 0;
      totalOccupancyRate += Number(property.occupiedPercentageByMonth[selectDate]) || 0;
      totalDailyRate += Number(property.avgDailyRentByMonth[selectDate]) || 0;
      totalOtherExpenses += Number(property.otherExpenseOfMonth[selectDate]) || 0;
    });

    return {
      totalGrossIncome: totalGrossIncome.toFixed(2),
      totalOccupiedDays,
      totalOccupancyRate: (totalOccupancyRate / filteredRooms.length).toFixed(2),
      totalDailyRate: (totalDailyRate / filteredRooms.length).toFixed(2),
      totalOtherExpenses: totalOtherExpenses.toFixed(2),
    };
  };

  const totals = calculateTotals();
  const columns = [
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={150}
          px={1}
          ml={2}
          style={{ fontSize: "16px", WidthFull, fontWeight: "medium", color: "#000" }}
        >
          Properties
        </MDTypography>
      ),
      accessor: "propertyName",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          textAlign
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000", width: "200px" }}
        >
          Gross income
        </MDTypography>
      ),
      accessor: "GrossIncome",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Occupancy(days)
        </MDTypography>
      ),
      accessor: "Occupancy",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Occupancy(rate)
        </MDTypography>
      ),
      accessor: "OccupancyRate",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          No. cleanings
        </MDTypography>
      ),
      accessor: "NoCleaning",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          width={120}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Daily rent rate
        </MDTypography>
      ),
      accessor: "DailyRate",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={2.5}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Cleaning expense
        </MDTypography>
      ),
      accessor: "CleaningExpense",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Rent
        </MDTypography>
      ),
      accessor: "Rent",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={170}
          px={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Other(expenses)
        </MDTypography>
      ),
      accessor: "Otherexpenses",
    },
  ];
  // const rows = filteredRooms.map((property) => ({
  //   propertyName: property.property_name || '',
  //   GrossIncome: Number(property.GrossIncomeByMonth[selectDate] ?? 0).toFixed(2),
  //   Occupancy: property.occupiedDaysByMonth[selectDate] ?? '0',
  //   OccupancyRate: (Number(property.occupiedPercentageByMonth[selectDate] ?? 0)).toFixed(2),
  //   DailyRate: (Number(property.avgDailyRentByMonth[selectDate] ?? 0)).toFixed(2),
  //   Otherexpenses: Number(property.otherExpenseOfMonth[selectDate] ?? 0).toFixed(2),
  // }));
  const rows = filteredRooms?.map((property) => ({
    propertyName: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {`${property.property_name}`}
      </MDTypography>
    ),
    GrossIncome: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {Number(property.GrossIncomeByMonth[selectDate] ?? 0).toFixed(2)}
      </MDTypography>
    ),
    Occupancy: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {Number(property.occupiedDaysByMonth[selectDate] ?? 0)}
      </MDTypography>
    ),
    OccupancyRate: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {Number(property.occupiedPercentageByMonth[selectDate] ?? 0).toFixed(2)}
      </MDTypography>
    ),
    NoCleaning: (
      <MDTypography
        variant="caption"
        color="text"
        ml={10}
        fontWeight="medium"
        style={{ fontSize: "14px" }}
      >
        {"" || ""}
      </MDTypography>
    ),
    DailyRate: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {isNaN(Number(property.avgDailyRentByMonth[selectDate] ?? 0))
          ? "0"
          : Number(property.avgDailyRentByMonth[selectDate] ?? 0).toFixed(2)}{" "}
      </MDTypography>
    ),
    CleaningExpense: (
      <MDTypography
        variant="caption"
        color="text"
        ml={13}
        fontWeight="medium"
        style={{ fontSize: "14px" }}
      >
        {property.guest || ""}
      </MDTypography>
    ),
    Rent: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {property.notes || ""}
      </MDTypography>
    ),
    Otherexpenses: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {Number(property.otherExpenseOfMonth[selectDate] ?? 0).toFixed(2)}
      </MDTypography>
    ),
    currency: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {property.currency || ""}
      </MDTypography>
    ),
    amount: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {property.amount || property.rent_amount || ""}
      </MDTypography>
    ),
    booking_source: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {property.booking_source || ""}
      </MDTypography>
    ),
    changed: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {property.changed || ""}
      </MDTypography>
    ),
    deposit_amount: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {property.deposit_amount || ""}
      </MDTypography>
    ),
    payment_method: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {property.payment_method || ""}
      </MDTypography>
    ),
    total_stay: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {property.total_stay || ""}
      </MDTypography>
    ),
  }));
  const totalRow = {
    propertyName: "Total",
    GrossIncome: totals.totalGrossIncome,
    Occupancy: totals.totalOccupiedDays,
    OccupancyRate: totals.totalOccupancyRate,
    DailyRate: totals.totalDailyRate,
    Otherexpenses: totals.totalOtherExpenses,
  };

  rows.push(totalRow);

  return (
    <>
      <DashboardLayout>
        <Sidenav color="primary" brandName="RentRoll" routes={routes} />
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6} mb={5}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  sx={{ backgroundColor: "#1e90ff" }}
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h5" color="white" style={{ float: "left" }}>
                    Monthly Report
                  </MDTypography>
                  <MDBox mt={1} mb={1} style={{ float: "right", display: "flex", gap: "10px" }}>
                    <TextField
                      placeholder="Search"
                      size="small"
                      sx={{
                        "& .MuiInputBase-root": {
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          border: "none",
                          padding: "3px 8px",
                        },
                        "& .MuiInputLabel-root": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input": {
                          padding: "8px",
                        },
                      }}
                      inputProps={{ "aria-label": "Search" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ fontSize: "18px" }}>
                            <SearchIcon color="black" fontWeight="bold" />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleSearch}
                      value={searchTerm}
                    />
                    <Button
                      style={{
                        color: "black",
                        border: "1px solid white",
                        background: "white",
                      }}
                      onClick={handleExport}
                    >
                      Download Report
                      <DownloadIcon />
                    </Button>
                    <FormControl variant="outlined" style={{ minWidth: 120 }}>
                      <InputLabel id="start-Month" style={{ color: "#ffff", marginTop: "-40px" }}>
                        Select Month
                      </InputLabel>
                      <TextField
                        id="start-month"
                        type="month"
                        variant="outlined"
                        onChange={handledatechange}
                        value={selectDate}
                        sx={{
                          "& .MuiInputBase-root": {
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            border: "none",
                            padding: "4px 10px",
                          },
                          "& .MuiInputLabel-root": {
                            display: "none",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            display: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: "6px",
                          },
                        }}
                        inputProps={{
                          "aria-label": "start-month",
                          min: "2014-01",
                          max: "2030-12",
                        }}
                      />
                    </FormControl>
                  </MDBox>
                </MDBox>
                {loading ? (
                  <MDTypography
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    align="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                  </MDTypography>
                ) : (
                  <MDBox pt={3}>
                    {filteredRooms.length > 0 ? (
                      <>
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          entriesPerPage={{ defaultValue: 10, entries: [ 10, 20 , 30 , 40 , 50 ,60] }}
                          showTotalEntries={false}
                          noEndBorder
                        />
                        
                      </>
                    ) : (
                      <MDTypography variant="h6" align="center" m={2} sx={{ color: "red" }}>
                        No Data Found .
                      </MDTypography>
                    )}
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
          <Card>
            {filteredRooms.length > 0 ? (
              <>
                <MDBox
                  mt={2}
                  
                  display="flex"
                  justifyContent="space-between"
                  bgcolor="#f5f5f5"
                  p={2}
                  borderRadius="8px"
                  sx={{
                    maxHeight: "300px", 
                    overflowX: "auto", 
                    overflowY: "auto", 
                  }}
                >
                  <MDTypography variant="h6" color="text" mt={2.7} style={{ fontWeight: "bold" }}>
                    Total
                  </MDTypography>
                  <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }}>
                    <MDTypography variant="h6" width={170} px={3} style={{ fontSize: "14px", fontWeight: "bold", color: "text" }}>Gross Income </MDTypography>
                    {totals.totalGrossIncome}
                  </MDTypography>
                  <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }}>
                  <MDTypography variant="h6" width={170} px={3} style={{ fontSize: "14px", fontWeight: "bold", color: "text" }}>Occupancy(days)</MDTypography>

                    {totals.totalOccupiedDays}
                  </MDTypography>
                  <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }}>
                  <MDTypography variant="h6" width={170} px={3} style={{ fontSize: "14px", fontWeight: "bold", color: "text" }}>Occupancy(rate) </MDTypography>

                    {totals.totalOccupancyRate}
                  </MDTypography>
                  <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }}>
                  <MDTypography variant="h6" width={170} px={3} style={{ fontSize: "14px", fontWeight: "bold", color: "text" }}>No.of cleaning </MDTypography>

                    {0 || 0}
                  </MDTypography>
                  <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }}>
                  <MDTypography variant="h6" width={170} px={3} style={{ fontSize: "14px", fontWeight: "bold", color: "text" }}>Daily rent </MDTypography>

                    {totals.totalDailyRate}
                  </MDTypography>
                  <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }}>
                  <MDTypography variant="h6" width={170} px={3} style={{ fontSize: "14px", fontWeight: "bold", color: "text" }}>Cleaning expenses</MDTypography>

                    {0 || 0}
                  </MDTypography>
                  <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }}>
                  <MDTypography variant="h6" width={170} px={3} style={{ fontSize: "14px", fontWeight: "bold", color: "text" }}>Rent </MDTypography>

                    {0 || 0}
                  </MDTypography>
                  <MDTypography variant="h6" align="center" >
                  <MDTypography variant="h6" width={170} px={3} style={{ fontSize: "14px", fontWeight: "bold", color: "text" }}>Other expenses </MDTypography>
                    {totals.totalOtherExpenses}
                  </MDTypography>
                </MDBox>
               
              </>
            ) : (
              <MDTypography variant="h6" align="center" m={2} sx={{ color: "red" }}>
                No Data Found.
              </MDTypography>
            )}
          </Card>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Index;
