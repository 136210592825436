import * as React from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Button, Card, Grid, Autocomplete, TextField, FormControl } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
const style = {
  position: "absolute",
  top: { xs: 340, sm: 340, md: 340, xl: 340 },
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
};

const CleaningUpdateModel = ({ isVisible, Close, Selectedclean, fetchCleaningData ,getCleaningDataOnId }) => {
  const [cleaningData,setCleaningData]=useState(null);
 const [RoomId,setRoomId]=useState(null);
 const [PropertyId,setPropertyId]=useState(null);
  useEffect(()=>{
    if(Selectedclean){
    setCleaningData(Selectedclean.cleaning_id);
    setRoomId(Selectedclean.room_id);
    setPropertyId(Selectedclean.property_id)
    }
  },[Selectedclean])
  const formik = useFormik({
    initialValues: {
      cleaner_email: "",
      cleaner_name: "",
      todo: "",
      maintenance: "",
      property_id: null,
      cleaning_date: "",
    },
    onSubmit: async (values) => {
      try {
        const formData = {
          ...values,
          property_id: PropertyId,
          room_id:RoomId,
        };
        let response;
     
        if (cleaningData) {
          response = await axios.put(
            `${process.env.REACT_APP_API_PATH}/updateCleaningData/${cleaningData}`,
            formData
          );
        }
       
        if (response.status === 200) {
          toast.success("Cleaning data updated successfully!");
        } else {
          toast.error("Failed to submit cleaning data.");
        }
        getCleaningDataOnId()
        fetchCleaningData(formData);
        formik.resetForm();
        closeModal();
      } catch (error) {
        console.error("Failed to add/update cleaning data. Please try again.", error);
      }
    },
  });

  const closeModal = () => {
    formik.resetForm();
    Close();
  };

  const isFormFilled = Object.values(formik.values).some((value) => value !== "");

  useEffect(() => {
    if (Selectedclean) {
      formik.setValues({
        cleaner_email: Selectedclean.cleaner_email || "",
        cleaner_name: Selectedclean.cleaner_name || "",
        todo: Selectedclean.todo || "",
        maintenance: Selectedclean.maintenance || "",
        cleaning_date: dayjs(Selectedclean.cleaning_date).format("YYYY-MM-DD") || "",
        Reservation: {
          reservation_id: Selectedclean.reservation_id,
          property_name: Selectedclean.property_name,
          room_no: Selectedclean.room_no,
        },
      });
    } else {
      formik.resetForm();
    }
  }, [Selectedclean]);
  const addReservationData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getroomReservation`);
      if (response.data.success && Array.isArray(response.data.data)) {
        const filteredReservations = response.data.data.filter((reservation) => {
          const departureDate = new Date(reservation.departure_date);
          const currentDate = new Date();

          currentDate.setHours(0, 0, 0, 0);

          return departureDate >= currentDate;
        });
        setReservation(filteredReservations);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    }
  };
  useEffect(() => {
    addReservationData();
  }, []);
  return (
    <>
    <Modal
      open={isVisible}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <Card
          sx={{
            ...style,
            width: {
              xs: "90%",
              sm: "80%",
              md: "500px",
              lg: "60%",
              xl: "600px",
            },
          }}
        >
          <MDBox
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
            sx={{ backgroundColor: "#1e90ff" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Updated Cleaning Data
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <Grid container spacing={2}>
              {[
                { label: "Cleaning Date", name: "cleaning_date", type: "date" },
                { label: "Cleaner name", name: "cleaner_name", type: "select" },
                { label: "Cleaner email", name: "cleaner_email", type: "email" },
                { label: "Todo List", name: "todo",type: "textarea" },
                { label: "Maintenance", name: "maintenance",type: "textarea" },
              ].map(({ label, name, options, type = "text" }) => (
                <Grid item xs={type === "date" ? 12 : 6} key={name}>
                  <MDBox mb={2}>
                    {name === "cleaning_date" ? (
                      <TextField
                        type={type}
                        label={label}
                        fullWidth
                        name={name}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          placeholder: type === "date" || type === "time" ? "" : undefined,
                        }}
                        error={formik.touched[name] && Boolean(formik.errors[name])}
                        helperText={formik.touched[name] && formik.errors[name]}
                      />
                    ) : type === "textarea" ? (
                      <TextField
                        label={label}
                        name={name}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                      />
                    ): options ? (
                      <Autocomplete
                        disablePortal
                        options={options}
                        value={formik.values[name]}
                        onChange={(event, value) => {
                          formik.setFieldValue(name, value);
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={label}
                            error={formik.touched[name] && Boolean(formik.errors[name])}
                          />
                        )}
                      />
                    ) : (
                      <MDInput
                        type={type}
                        label={label}
                        fullWidth
                        name={name}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          placeholder: type === "date" || type === "time" ? "" : undefined,
                        }}
                      />
                    )}
                    <div style={{ color: "red", fontSize: "17px" }}>
                      {formik.touched[name] && formik.errors[name] && (
                        <div>{formik.errors[name]}</div>
                      )}
                    </div>
                  </MDBox>
                </Grid>
              ))}

              {/* <Grid item xs={6}>
                <MDBox mb={2}>
                  <FormControl fullWidth variant="outlined">
                    <Autocomplete
                      options={Reservation}
                      getOptionLabel={(option) =>
                        option?.property_name && option?.room_no
                          ? `${option.property_name} - Room ${option.room_no}`
                          : ""
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue("Reservation", value);
                        handleReservationChange(value);
                      }}
                      value={formik.values.Reservation || ""}
                      renderInput={(params) => <TextField {...params} label="Reservation" />}
                    />
                  </FormControl>
                </MDBox>
              </Grid> */}
            </Grid>
          
            <MDBox mt={4} mb={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                    <MDButton
                sx={{ backgroundColor: "#0d6efd" }}
                color="info"
                fullWidth
                type="submit"
                disabled={!isFormFilled}
              >
                Submit
              </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <MDButton
                  sx={{ backgroundColor: "#0d6efd" }}
                  color="info"
                  fullWidth  onClick={closeModal}>
                  Close
                </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
          </MDBox>
        </Card>
      </form>
    </Modal>
  </>
  );
};

export default CleaningUpdateModel;
